import * as React from 'react';
import { Toolbar, Typography, IconButton, Menu, MenuItem, Button } from '@mui/material';
import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { useAuth0 } from "@auth0/auth0-react";

const TopNav = ({ handleDrawerOpen, open, setOpen }) => {
  const drawerWidth = 240;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isAuthenticated, logout, loginWithRedirect, user } = useAuth0();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setOpen(isAuthenticated);
  }, [isAuthenticated])

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        {isAuthenticated ? <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton> : ""}
        <Typography variant="h6" noWrap component="div">
          <img
            src="https://wamstage.bapihvac.com/bapi-logo.png"
            height="50"
            alt="BAPI Logo"
            loading="lazy"
          />
        </Typography>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        </Typography>
        {
          isAuthenticated
            ?
            <>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{ p: 0 }}
              >
                <AccountCircle style={{ marginRight: "10px" }} />
                <span style={{ fontSize: "17px" }}>{user.name}</span>
              </IconButton>
              <Button color="inherit" onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button>
            </>
            : <Button color="inherit" onClick={() => loginWithRedirect()}>Login</Button>}
      </Toolbar>
    </AppBar>
  )
}

export default TopNav;