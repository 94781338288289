import React, { forwardRef, useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

// Editor is an uncontrolled React component
export const RichEditor = forwardRef(
  ({ readonly, defaultValue, onTextChange, className = "" }, ref) => {
    const containerRef = useRef(null);
    const [quill, setQuill] = useState();

    useEffect(() => {
      if (quill) {
        quill.setContents(quill.clipboard.convert({ html: defaultValue }));
      }
    }, [defaultValue, quill]);

    useEffect(() => {
      ref?.current?.enable(!readonly);
    }, [ref, readonly]);

    useEffect(() => {
      const container = containerRef.current;

      if (container) {
        const editorContainer = container.appendChild(container.ownerDocument.createElement("div"));
        const q = new Quill(editorContainer, {
          theme: "snow",
          modules: {
            toolbar: [
              [{ header: [1, 2, 3, 4, false] }],
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
              ['align', { 'color': [] }, { 'background': [] }],
              [{ script: 'super' }, { script: 'sub' }],
              ["link"],
              ['code-block', "clean"],
            ],
          },
        });

        ref.current = q;

        q.on(Quill.events.TEXT_CHANGE, () => onTextChange(q.getSemanticHTML()));

        setQuill(q);
      }

      return () => {
        ref.current = null;
        container.innerHTML = "";
      };
    }, [ref]);

    return (
      <div className={className} ref={containerRef}></div>
    );
  },
);
