import * as React from 'react';
import { useAuth0 } from "@auth0/auth0-react";


import AppLayout from '../layouts/AppLayout';
import GatewayFirmwareUpload from '../components/GatewayFirmwareUpload';
import { setAuthToken } from '../api';

const GatewayUpload = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    async function getToken() {
      const result = await getAccessTokenSilently();
      setAuthToken(result);
    }

    if (isAuthenticated) {
      getToken().catch(console.error);
    }
  }, [isAuthenticated, getAccessTokenSilently])

  return (
    <AppLayout>
      {isAuthenticated ? <GatewayFirmwareUpload /> : ""}
    </AppLayout>
  )
}

export default GatewayUpload;
