import * as React from 'react';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { Drawer, IconButton, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Inbox, ChevronLeft, ChevronRight } from '@mui/icons-material';

import { DrawerHeader } from '../styles';
import { Env } from "../utils/environment";

const SideNav = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const drawerWidth = 240;

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <>
            <ListItem disablePadding component={Link} to="/firmware/gateway">
              <ListItemButton>
                <ListItemIcon>
                  <Inbox />
                </ListItemIcon>
                <ListItemText primary={'Upload Gateway Firmware '} />
              </ListItemButton>
            </ListItem>
            {Env.ENABLE_DEVICE_UPLOAD ?
              <ListItem disablePadding component={Link} to="/firmware/device">
                <ListItemButton>
                  <ListItemIcon>
                    <Inbox />
                  </ListItemIcon>
                  <ListItemText primary={'Upload Device Firmware'} />
                </ListItemButton>
              </ListItem> : ""
            }
          </>
        </List>
      </Drawer>
    </>
  )
}

export default SideNav;