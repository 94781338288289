import React, { useRef, useState } from "react";
import { Alert, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { uploadDeviceFoodProbeFirmware, uploadDeviceSensorNodeFirmware } from '../api';
import { useAuth0 } from "@auth0/auth0-react";
import { RichEditor } from './RichEditor';

const FileType = {
  FOOD_PROBE: "FOOD_PROBE",
  FOOD_PROBE_SIG: "FOOD_PROBE_SIGNATURE",
  FOOD_PROBE_CHANGELOG: 'FOOD_PROBE_CHANGELOG',

  SENSOR_NODE: "SENSOR_NODE",
  SENSOR_NODE_SIG: "SENSOR_NODE_SIGNATURE",
  SENSOR_NODE_CHANGELOG: 'SENSOR_NODE_CHANGELOG',
}

const DeviceFirmwareUpload = () => {
  const { user } = useAuth0();

  const [deviceFoodProbeFile, setDeviceFoodProbeFile] = useState(null);
  const [deviceFoodProbeSignaturefile, setDeviceFoodProbeSignaturefile] = useState(null);

  const [deviceSensorNodeFile, setDeviceSensorNodeFile] = useState(null);
  const [deviceSensorNodeSignaturefile, setDeviceSensorNodeSignaturefile] = useState(null);

  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const [errorDeviceFoodProbeHelpText, setErrorFoodProbeHelpText] = useState("");
  const [errorDeviceFoodProbeSignatureHelpText, setErrorDeviceFoodProbeSignatureHelpText] = useState("");

  const [errorDeviceSensorNodeHelpText, setErrorDeviceSensorNodeHelpText] = useState("");
  const [errorDeviceSensorNodeSignatureHelpText, setErrorDeviceSensorNodeSignatureHelpText] = useState("");

  const [sensorNodeChangelogText, setSensorNodeChangelogText] = useState("");
  const [foodProbeChangelogText, setFoodProbeChangelogText] = useState("");

  const deviceSensorNodeFileRef = useRef(null);
  const deviceFoodProbeFileRef = useRef(null);

  const deviceSensorNodeSignatureFileRef = useRef(null);
  const deviceFoodProbeSignatureFileRef = useRef(null);

  const quillSensorNodeRef = useRef();
  const quillFoodProbeRef = useRef();

  const clearFilesAfterSave = () => {
    console.log("clear files after save");

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("uploadedBy", user.name);

    if (deviceFoodProbeFile && deviceFoodProbeSignaturefile) {
      formData.append("deviceFilename", deviceFoodProbeFile.name);
      formData.append("deviceSignatureFilename", deviceFoodProbeSignaturefile.name);
      formData.append("changelog", foodProbeChangelogText)

      formData.append("files", deviceFoodProbeFile);
      formData.append("files", deviceFoodProbeSignaturefile);

      try {
        const response = await uploadDeviceFoodProbeFirmware(formData);
        if (response) {
          setUploadSuccess(true);
          setUploadError(false);
          setUploadMessage(response.message);

          clearFilesAfterSave();
        }
      } catch (e) {
        if (e.response && e.response.data) {
          setUploadMessage(e.response.data.message);
          setUploadError(true);
          setUploadSuccess(false);
        }
      }
    }

    if (deviceSensorNodeFile && deviceSensorNodeSignaturefile) {
      formData.append("deviceFilename", deviceSensorNodeFile.name)
      formData.append("deviceSignatureFilename", deviceSensorNodeSignaturefile.name)
      formData.append("changelog", sensorNodeChangelogText)

      formData.append("files", deviceSensorNodeFile);
      formData.append("files", deviceSensorNodeSignaturefile);

      try {
        const response = await uploadDeviceSensorNodeFirmware(formData);
        if (response) {
          setUploadSuccess(true);
          setUploadError(false);
          setUploadMessage(response.message);

          clearFilesAfterSave();
        }
      } catch (e) {
        if (e.response && e.response.data) {
          setUploadMessage(e.response.data.message);
          setUploadError(true);
          setUploadSuccess(false);
        }
      }
    }
  };

  const NoFileUploaded = (e, fileType) => {
    if (!e.target.files.length) {
      switch (fileType) {
        case FileType.FOOD_PROBE:
          setDeviceFoodProbeFile(null);
          setErrorFoodProbeHelpText("Please upload a file.");
          break;
        case FileType.FOOD_PROBE_SIG:
          setDeviceFoodProbeSignaturefile(null);
          setErrorDeviceFoodProbeSignatureHelpText("Please upload a file.");
          break;
        case FileType.SENSOR_NODE:
          setDeviceSensorNodeFile(null);
          setErrorDeviceSensorNodeHelpText("Please upload a file.");
          break;
        case FileType.SENSOR_NODE_SIG:
          setDeviceSensorNodeSignaturefile(null);
          setErrorDeviceSensorNodeSignatureHelpText("Please upload a file.");
          break;
      }

      return true;
    }
  }

  const handleSensorNodeSignatureFileChange = (e) => {
    if (NoFileUploaded(e)) return;

    if (process.env.REACT_APP_ALLOWED_SIGNATURE_EXTENSIONS) {
      const allowedExtensions = process.env.REACT_APP_ALLOWED_SIGNATURE_EXTENSIONS.split(',');
      const file = e.target.files[ 0 ];
      const fileExtension = file.name.split('.')[ 1 ];

      if (!allowedExtensions.includes(fileExtension)) {
        e.target.value = null;
        setDeviceSensorNodeSignaturefile(null);
        setErrorDeviceSensorNodeSignatureHelpText(`Allowed extensions are: ${allowedExtensions}`);


        return;
      }

      setDeviceSensorNodeSignaturefile(file)

    } else {
      e.target.value = null;
      setDeviceSensorNodeSignaturefile(null);
      setErrorDeviceSensorNodeSignatureHelpText("Extension validation is disabled. Unable to upload a file.");

    }
  }

  const handleFoodProbeSignatureFileChange = (e) => {
    if (NoFileUploaded(e, FileType.BLE_SIG)) return;

    if (process.env.REACT_APP_ALLOWED_SIGNATURE_EXTENSIONS) {
      const allowedExtensions = process.env.REACT_APP_ALLOWED_SIGNATURE_EXTENSIONS.split(',');
      const file = e.target.files[ 0 ];
      const fileExtension = file.name.split('.')[ 1 ];

      if (!allowedExtensions.includes(fileExtension)) {
        e.target.value = null;
        setDeviceFoodProbeSignaturefile(null);
        setErrorDeviceFoodProbeSignatureHelpText(`Allowed extensions are: ${allowedExtensions}`);


        return;
      }

      setDeviceFoodProbeSignaturefile(file);

    } else {
      e.target.value = null;
      setDeviceFoodProbeSignaturefile(null);
      setErrorDeviceFoodProbeSignatureHelpText("Extension validation is disabled. Unable to upload a file.");

    }
  }

  const handleFoodProbeFileChange = (e) => {
    if (NoFileUploaded(e)) return;

    if (process.env.REACT_APP_ALLOWED_FIRMWARE_EXTENSIONS) {
      const allowedExtensions = process.env.REACT_APP_ALLOWED_FIRMWARE_EXTENSIONS.split(', ');
      const file = e.target.files[ 0 ];
      const fileExtension = file.name.split('.')[ 1 ];

      if (!allowedExtensions.includes(fileExtension)) {
        e.target.value = null;
        setDeviceFoodProbeFile(null);
        setErrorFoodProbeHelpText(`Allowed extensions are: ${allowedExtensions}`);


        return;
      }

      setDeviceFoodProbeFile(file);

    } else {
      e.target.value = null;
      setDeviceFoodProbeFile(null);
      setErrorFoodProbeHelpText("Extension validation is disabled. Unable to upload a file.");
    }
  }

  const handleSensorNodeFileChange = (e) => {
    if (NoFileUploaded(e, FileType.UC)) return;

    if (process.env.REACT_APP_ALLOWED_FIRMWARE_EXTENSIONS) {
      const allowedExtensions = process.env.REACT_APP_ALLOWED_FIRMWARE_EXTENSIONS.split(', ');
      const file = e.target.files[ 0 ];
      const fileExtension = file.name.split('.')[ 1 ];

      if (!allowedExtensions.includes(fileExtension)) {
        e.target.value = null;
        setDeviceSensorNodeFile(null);
        setErrorDeviceSensorNodeHelpText(`Allowed extensions are: ${allowedExtensions}`);


        return;
      }

      setDeviceSensorNodeFile(file);

    } else {
      e.target.value = null;
      setDeviceSensorNodeFile(null);
      setErrorDeviceSensorNodeHelpText("Extension validation is disabled. Unable to upload a file.");

    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h4" mb={6}>
        Device Firmware
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Typography variant="h6">
            Device Sensor Node Firmware Upload
          </Typography>
          {uploadError && deviceSensorNodeFile
            ? <Alert severity="error">{uploadMessage}</Alert>
            : uploadSuccess && !uploadError && deviceSensorNodeFile ? <Alert severity="success">{uploadMessage}</Alert>
              : ""
          }
        </Stack>
        <Stack spacing={2}>
          <Typography variant="label">
            Firmware File
          </Typography>
          <TextField helperText={`${errorDeviceSensorNodeHelpText ? errorDeviceSensorNodeHelpText : ''}`}
                     id={`standard-basic ${errorDeviceSensorNodeHelpText ? 'standard-error-helper-text' : ''}`} label=""
                     name={FileType.SENSOR_NODE} variant="standard" type="file" onChange={handleSensorNodeFileChange}
                     ref={deviceSensorNodeFileRef}/>
        </Stack>
        <Stack mt={5} mb={2}>
          <Typography variant="label" mb={2}>
            File Signature
          </Typography>
          <TextField
            helperText={`${errorDeviceSensorNodeSignatureHelpText ? errorDeviceSensorNodeSignatureHelpText : ''}`}
            id={`standard-basic ${errorDeviceSensorNodeSignatureHelpText ? 'standard-error-helper-text' : ''}`} label=""
            variant="standard" type="file" onChange={handleSensorNodeSignatureFileChange}
            name={FileType.SENSOR_NODE_SIG} ref={deviceSensorNodeSignatureFileRef}/>
        </Stack>
        <Stack spacing={2} mt={5} mb={2}>
          <Typography variant="label">
            Changelog
          </Typography>
          <RichEditor
            className="text-editor"
            ref={quillSensorNodeRef}
            readonly={false}
            onTextChange={setSensorNodeChangelogText}
          />
        </Stack>
        <Button variant="contained" type="submit">Upload Sensor Node Firmware</Button>
      </form>
      <Stack mt={5}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Typography variant="h6">
              Device Food Probe Firmware Upload
            </Typography>
            {uploadError && deviceFoodProbeFile
              ? <Alert severity="error">{uploadMessage}</Alert>
              : uploadSuccess && !uploadError && deviceFoodProbeFile ? <Alert severity="success">{uploadMessage}</Alert>
                : ""
            }
          </Stack>
          <Stack spacing={2}>
            <Typography variant="label">
              Firmware File
            </Typography>
            <TextField helperText={`${errorDeviceFoodProbeHelpText ? errorDeviceFoodProbeHelpText : ''}`}
                       id={`standard-basic ${errorDeviceFoodProbeHelpText ? 'standard-error-helper-text' : ''}`}
                       label="" variant="standard" type="file" onChange={handleFoodProbeFileChange}
                       name={FileType.FOOD_PROBE} ref={deviceFoodProbeFileRef}/>
          </Stack>
          <Stack spacing={2} mt={5} mb={2}>
            <Typography variant="label" mb={2}>
              File Signature
            </Typography>
            <TextField
              helperText={`${errorDeviceFoodProbeSignatureHelpText ? errorDeviceFoodProbeSignatureHelpText : ''}`}
              id={`standard-basic ${errorDeviceFoodProbeSignatureHelpText ? 'standard-error-helper-text' : ''}`}
              label="" variant="standard" type="file" onChange={handleFoodProbeSignatureFileChange}
              name={FileType.FOOD_PROBE_SIG} ref={deviceFoodProbeSignatureFileRef}/>
          </Stack>
          <Stack spacing={2} mt={5} mb={2}>
            <Typography variant="label">
              Changelog
            </Typography>
            <RichEditor
              className="text-editor"
              ref={quillFoodProbeRef}
              readonly={false}
              onTextChange={setSensorNodeChangelogText}
            />
          </Stack>
          <Button variant="contained" type="submit">Upload Food Probe Firmware</Button>
        </form>
      </Stack>
    </Box>
  )
}

export default DeviceFirmwareUpload;