import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import './index.css';
import Home from './views/Home';
import GatewayUpload from './views/GatewayUpload';
import DeviceUpload from './views/DeviceUpload';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        useRefreshTokens={true}
        cacheLocation="localstorage">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/firmware/gateway" element={<GatewayUpload />} />
          <Route path="/firmware/device" element={<DeviceUpload />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
