import axios from "axios"
import { createAuth0Client } from '@auth0/auth0-spa-js';

// this is a local client for api calls.. 
// we were not able to access the token outside of react context
// so we need to create this client
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000
});

export const setAuthToken = (accessToken) => {
  if (accessToken) {
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    api.defaults.headers.common.Authorization = null;
  }
}


//http://localhost:8000/api/firmware/gateway/upload
export const uploadGatewayUcFirmware = async (formData) => {
  const response = await api.post('/api/firmware/gateway-uc', formData)

  if (response.data) {
    return response.data;
  }

  return null;
}

export const uploadGatewayBleFirmware = async (formData) => {
  const response = await api.post('/api/firmware/gateway-ble', formData)

  if (response.data) {
    return response.data;
  }

  return null;
}

export const uploadDeviceFoodProbeFirmware = async (formData) => {
  const response = await api.post('api/firmware/device-fb', formData)

  if (response.data) {
    return response.data;
  }

  return null;
}

export const uploadDeviceSensorNodeFirmware = async (formData) => {
  const response = await api.post('api/firmware/device-sn', formData);

  if (response.data) {
    return response.data;
  }

  return null;
}