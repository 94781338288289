import React, { useRef, useState } from "react";
import { Alert, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { uploadGatewayBleFirmware, uploadGatewayUcFirmware } from '../api';
import { useAuth0 } from "@auth0/auth0-react";
import { RichEditor } from './RichEditor';

const FileType = {
  UC: "UC",
  UC_SIG: "UC_SIGNATURE",
  UC_CHANGELOG: 'UC_CHANGELOG',

  BLE: "BLE",
  BLE_SIG: "BLE_SIGNATURE",
  BLE_CHANGELOG: 'BLE_CHANGELOG'
}

const GatewayFirmwareUpload = () => {
  const { user } = useAuth0();
  const [UcFile, setUcFile] = useState(null);
  const [UcSignaturefile, setUcSignatureFile] = useState(null);
  const [Blefile, setBleFile] = useState(null);
  const [BleSignaturefile, setBleSignatureFile] = useState(null);

  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const [errorBleHelpText, setErrorBleHelpText] = useState("");
  const [errorBleSignatureHelpText, setErrorBleSignatureHelpText] = useState("");
  const [errorUcHelpText, setErrorUcHelpText] = useState("");
  const [errorUcSignatureHelpText, setErrorUcSignatureHelpText] = useState("");

  const [ucChangelogText, setUcChangelogText] = useState("");
  const [bleChangelogText, setBleChangelogText] = useState("");

  const quilBleRef = useRef();
  const quilUcRef = useRef();

  const clearFilesAfterSave = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("uploadedBy", user.name);

    if (Blefile && BleSignaturefile) {
      formData.append("bleFilename", Blefile.name);
      formData.append("bleSignatureFilename", BleSignaturefile.name);
      formData.append("bleChangelog", bleChangelogText);

      formData.append("files", Blefile);
      formData.append("files", BleSignaturefile);

      try {
        const response = await uploadGatewayBleFirmware(formData);
        if (response) {
          setUploadSuccess(true);
          setUploadError(false);
          setUploadMessage(response.message);

          clearFilesAfterSave();
        }
      } catch (e) {
        if (e.response && e.response.data) {
          setUploadMessage(e.response.data.message);
          setUploadError(true);
          setUploadSuccess(false);
        }
      }
    }

    if (UcFile && UcSignaturefile) {
      formData.append("ucFilename", UcFile.name)
      formData.append("ucSignatureFilename", UcSignaturefile.name)
      formData.append("ucChangelog", ucChangelogText);

      formData.append("files", UcFile);
      formData.append("files", UcSignaturefile);

      try {
        const response = await uploadGatewayUcFirmware(formData);
        if (response) {
          setUploadSuccess(true);
          setUploadError(false);
          setUploadMessage(response.message);
          clearFilesAfterSave();
        }
      } catch (e) {
        if (e.response && e.response.data) {
          setUploadMessage(e.response.data.message);
          setUploadError(true);
          setUploadSuccess(false);
        }
      }
    }
  };

  const NoFileUploaded = (e, fileType) => {
    if (!e.target.files.length) {
      switch (fileType) {
        case FileType.BLE:
          setBleFile(null);
          setErrorBleHelpText("Please upload a file.");
          break;
        case FileType.BLE_SIG:
          setBleSignatureFile(null);
          setErrorBleSignatureHelpText("Please upload a file.");
          break;
        case FileType.UC:
          setUcFile(null);
          setErrorUcHelpText("Please upload a file.");
          break;
        case FileType.UC_SIG:
          setUcSignatureFile(null);
          setErrorUcSignatureHelpText("Please upload a file.");
          break;
      }

      return true;
    }
  }

  const handleUcSignatureChange = (e) => {
    if (NoFileUploaded(e)) return;

    if (process.env.REACT_APP_ALLOWED_SIGNATURE_EXTENSIONS) {
      const allowedExtensions = process.env.REACT_APP_ALLOWED_SIGNATURE_EXTENSIONS.split(',');
      const file = e.target.files[ 0 ];
      const fileExtension = file.name.split('.')[ 1 ];

      if (!allowedExtensions.includes(fileExtension)) {
        e.target.value = null;
        setUcSignatureFile(null);
        setErrorUcSignatureHelpText(`Allowed extensions are: ${allowedExtensions}`);


        return;
      }

      setUcSignatureFile(file)

    } else {
      e.target.value = null;
      setUcSignatureFile(null);
      setErrorUcSignatureHelpText("Extension validation is disabled. Unable to upload a file.");

    }
  }

  const handleBleSignatureChange = (e) => {
    if (NoFileUploaded(e, FileType.BLE_SIG)) return;

    if (process.env.REACT_APP_ALLOWED_SIGNATURE_EXTENSIONS) {
      const allowedExtensions = process.env.REACT_APP_ALLOWED_SIGNATURE_EXTENSIONS.split(',');
      const file = e.target.files[ 0 ];
      const fileExtension = file.name.split('.')[ 1 ];

      if (!allowedExtensions.includes(fileExtension)) {
        e.target.value = null;
        setBleSignatureFile(null);
        setErrorBleSignatureHelpText(`Allowed extensions are: ${allowedExtensions}`);


        return;
      }

      setBleSignatureFile(file);

    } else {
      e.target.value = null;
      setBleSignatureFile(null);
      setErrorBleSignatureHelpText("Extension validation is disabled. Unable to upload a file.");

    }
  }

  const handleBleFileChange = (e) => {
    if (NoFileUploaded(e)) return;

    if (process.env.REACT_APP_ALLOWED_FIRMWARE_EXTENSIONS) {
      const allowedExtensions = process.env.REACT_APP_ALLOWED_FIRMWARE_EXTENSIONS.split(', ');
      const file = e.target.files[ 0 ];
      const fileExtension = file.name.split('.')[ 1 ];

      if (!allowedExtensions.includes(fileExtension)) {
        e.target.value = null;
        setBleFile(null);
        setErrorBleHelpText(`Allowed extensions are: ${allowedExtensions}`);


        return;
      }

      setBleFile(file);

    } else {
      e.target.value = null;
      setBleFile(null);
      setErrorBleHelpText("Extension validation is disabled. Unable to upload a file.");
    }
  }

  const handleUcFileChange = (e) => {
    if (NoFileUploaded(e, FileType.UC)) return;

    if (process.env.REACT_APP_ALLOWED_FIRMWARE_EXTENSIONS) {
      const allowedExtensions = process.env.REACT_APP_ALLOWED_FIRMWARE_EXTENSIONS.split(', ');
      const file = e.target.files[ 0 ];
      const fileExtension = file.name.split('.')[ 1 ];

      if (!allowedExtensions.includes(fileExtension)) {
        e.target.value = null;
        setUcFile(null);
        setErrorUcHelpText(`Allowed extensions are: ${allowedExtensions}`);


        return;
      }

      setUcFile(file);

    } else {
      e.target.value = null;
      setUcFile(null);
      setErrorUcHelpText("Extension validation is disabled. Unable to upload a file.");

    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h4" mb={6}>
        Gateway Firmware
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Typography variant="h6">
            UC Firmware Upload
          </Typography>
          {uploadError && UcFile
            ? <Alert severity="error">{uploadMessage}</Alert>
            : uploadSuccess && !uploadError && UcFile ? <Alert severity="success">{uploadMessage}</Alert>
              : ""
          }
        </Stack>
        <Stack spacing={2}>
          <Typography variant="label">
            Firmware File
          </Typography>
          <TextField helperText={`${errorUcHelpText ? errorUcHelpText : ''}`}
                     id={`standard-basic ${errorUcHelpText ? 'standard-error-helper-text' : ''}`} label=""
                     name={FileType.UC} variant="standard" type="file" onChange={handleUcFileChange}/>
        </Stack>
        <Stack mt={2} mb={2}>
          <Typography variant="label" mb={2}>
            File Signature
          </Typography>
          <TextField helperText={`${errorUcSignatureHelpText ? errorUcSignatureHelpText : ''}`}
                     id={`standard-basic ${errorUcSignatureHelpText ? 'standard-error-helper-text' : ''}`} label=""
                     variant="standard" type="file" onChange={handleUcSignatureChange} name={FileType.UC_SIG}/>
        </Stack>
        <Stack mb={2} spacing={2}>
          <Typography variant="label">
            Changelog
          </Typography>
          <RichEditor
            className="text-editor"
            ref={quilUcRef}
            readonly={false}
            onTextChange={setUcChangelogText}
          />
        </Stack>
        <Button variant="contained" type="submit">Upload UC Firmware</Button>
      </form>
      <Stack mt={5}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Typography variant="h6">
              BLE Firmware Upload
            </Typography>
            {uploadError && Blefile
              ? <Alert severity="error">{uploadMessage}</Alert>
              : uploadSuccess && !uploadError && Blefile ? <Alert severity="success">{uploadMessage}</Alert>
                : ""
            }
          </Stack>
          <Stack spacing={2} mb={2}>
            <Typography variant="label">
              Firmware File
            </Typography>
            <TextField
              helperText={`${errorBleHelpText ? errorBleHelpText : ''}`}
              id={`standard-basic ${errorBleHelpText ? 'standard-error-helper-text' : ''}`}
              label=""
              variant="standard"
              type="file"
              onChange={handleBleFileChange}
              name={FileType.BLE}/>
          </Stack>
          <Stack spacing={2} mb={2}>
            <Typography variant="label" mb={2}>
              File Signature
            </Typography>
            <TextField
              helperText={`${errorBleSignatureHelpText ? errorBleSignatureHelpText : ''}`}
              id={`standard-basic ${errorBleSignatureHelpText ? 'standard-error-helper-text' : ''}`}
              label=""
              variant="standard"
              type="file"
              onChange={handleBleSignatureChange}
              name={FileType.BLE_SIG}/>
          </Stack>
          <Stack spacing={2} mb={2}>
            <Typography variant="label">
              Changelog
            </Typography>
            <RichEditor
              className="text-editor"
              ref={quilBleRef}
              readonly={false}
              onTextChange={setUcChangelogText}
            />
          </Stack>
          <Button variant="contained" type="submit">Upload BLE Firmware</Button>
        </form>
      </Stack>
    </Box>
  )
}

export default GatewayFirmwareUpload;