import * as React from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import AppLayout from '../layouts/AppLayout';
import { setAuthToken } from '../api';

const Home = () => {
  const [loading, setLoading] = React.useState(false);
  const { isLoading, isAuthenticated, error, getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    async function getToken() {
      const result = await getAccessTokenSilently();
      setAuthToken(result);
    }

    setLoading(true);
    setTimeout(() => {
      if (isAuthenticated && !isLoading) {
        setLoading(false);
        getToken().catch(console.error);
      } else if (!isLoading && !isAuthenticated) {
        setLoading(false);
      } else {
        setLoading(true)
      }
    }, 1000);
  }, [isAuthenticated, isLoading])

  const renderMessage = () => {
    if (loading) {
      return (<>
        <p>Please wait.. Loading.</p>
      </>)
    }

    if (error) {
      return (<>
        <p>Oops. There was an error attempting to you log you in.</p>
      </>)
    }

    if (!isAuthenticated && !isLoading && !loading) {
      return (
        <>
          <p>Please login to use the application.</p>
        </>
      )
    }

    if (!isLoading && !loading && !error && isAuthenticated) {
      return (
        <>
          <p>This application is used to upload firmware files for BAPI devices. </p>
        </>
      )
    }
  }

  return (
    <AppLayout>
      <h5>Welcome to BAPI Admin Panel.</h5>
      {renderMessage()}
    </AppLayout>
  )
}



export default Home;
