import * as React from 'react';
import { Box, CssBaseline, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import { Main, DrawerHeader } from '../styles';
import TopNav from '../components/TopNav';
import SideNav from '../components/SideNav';

function AppLayout({ children }) {
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth0();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    React.useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            navigate("/");
        }
    }, [isAuthenticated, isLoading])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {

    }, [isAuthenticated])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <TopNav
                handleDrawerOpen={handleDrawerOpen}
                open={open}
                setOpen={setOpen}
            />
            <SideNav open={open} handleDrawerClose={handleDrawerClose} />
            <Main theme={theme} open={open}>
                <DrawerHeader />
                {children}
            </Main>
        </Box>
    )
}

export default AppLayout;
